import '@fontsource/raleway';
import '@fontsource/heebo';
import '@fontsource/heebo/200.css';
import '@fontsource/heebo/300.css';
import '@fontsource/heebo/500.css';
import '@fontsource/heebo/600.css';
import '@fontsource/heebo/700.css';
import 'bootstrap/dist/css/bootstrap.min.css';
const { CustomProvider } = require('./custom-context');
const React = require('react');

export function wrapPageElement({ element, props }) {
	return <CustomProvider {...props}>{element}</CustomProvider>;
}

export function onRouteUpdate({ location, prevLocation }) {
	if (typeof window !== 'undefined') {
		// Ignore scrolling when navigating to a hash
		if (location?.hash?.startsWith('#')) {
			return;
		}

		// Ensure the scroll-to-top behavior works on page transitions
		setTimeout(() => {
			window.scrollTo({ top: 0, behavior: 'smooth' });

			// Optional: Ensure elements exist before scrolling
			const bodyChildren = document.getElementById('bodyChildren');
			const gatsbyWrapper = document.getElementById('gatsby-focus-wrapper');

			if (bodyChildren) {
				bodyChildren.scrollIntoView({ behavior: 'smooth' });
			}
			if (gatsbyWrapper) {
				gatsbyWrapper.scrollIntoView({ behavior: 'smooth' });
			}
		}, 100); // Small delay ensures the DOM is ready
	}
}
